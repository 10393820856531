import { Fragment, useEffect, useState } from "react";
import axiosInstance from '../../axiosInstance';
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';

function CompanyList(){
    let navigate =useNavigate();
    const OnEditClick=(id)=>{
      navigate('/admin/addcompany/' + id);
    }
    const routeChange = () =>{ 
        navigate('/admin/addcompany');
      }
    const [ companylist, setcompanylist ] = useState([]);
    useEffect(()=>{
        axiosInstance.get('Company/GetCompanyMasters').then((response)=>{
        console.log(response.data);
        setcompanylist(response.data);
       });
       
    },[])
    return (
        <Fragment>
          <table>
            <tr>
              <th>
              <Button variant="contained" onClick={routeChange}>Add Company</Button>
              </th>
              <th>
              <h4>&nbsp;&nbsp;  Company list</h4>  
              </th>
            </tr>
          </table>
          <br></br>
           <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            ID
          </th>
          <th scope="col">
            Name
          </th>
          <th scope="col">
            City
          </th>
          <th scope="col">
            Mobile
          </th>
          <th scope="col">
            
          </th>
        </tr>
      </thead>
      <tbody>
        {companylist.map((item) => (
          <tr key={item.CompanyId}>
            <td>{item.CompanyId}</td>
            <td>{item.CompanyName}</td>
            <td>{item.CityName}</td>
            <td>{item.MobileNo}</td>
            <td><EditIcon onClick={()=> OnEditClick(item.CompanyId)} /></td>
          </tr>
        ))}
      </tbody>
    </table>
        </Fragment>
    )
}
export default CompanyList;