import React, { Fragment } from "react";
function MainSlider(){
    return (
        <Fragment>
           <>
  {/* main-slider */}
  <section className="w3l-main-slider" id="home">
    <div className="companies20-content">
      <div className="owl-one owl-carousel owl-theme">
        <div className="item">
          <li>
            <div className="slider-info banner-view bg bg2">
              <div className="banner-info">
                <div className="container">
                  <div className="banner-info-bg">
                    <h6>We are Creative</h6>
                    <h5>World IT Service company </h5>
                    <p className="mt-md-4 mt-3">
                      Get the most of reduction in your team’s operating creates
                      amazing UI/UX experiences.{" "}
                    </p>
                    <a
                      className="btn btn-style btn-primary mt-sm-5 mt-4 mr-2"
                      href="about.html"
                    >
                      {" "}
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div>
        <div className="item">
          <li>
            <div className="slider-info  banner-view banner-top1 bg bg2">
              <div className="banner-info">
                <div className="container">
                  <div className="banner-info-bg">
                    <h6>We are Creative</h6>
                    <h5>World IT Service company </h5>
                    <p className="mt-md-4 mt-3">
                      Get the most of reduction in your team’s operating creates
                      amazing UI/UX experiences.{" "}
                    </p>
                    <a
                      className="btn btn-style btn-primary mt-sm-5 mt-4 mr-2"
                      href="about.html"
                    >
                      {" "}
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div>
        <div className="item">
          <li>
            <div className="slider-info banner-view banner-top2 bg bg2">
              <div className="banner-info">
                <div className="container">
                  <div className="banner-info-bg">
                    <h6>We are Creative</h6>
                    <h5>World IT Service company </h5>
                    <p className="mt-md-4 mt-3">
                      Get the most of reduction in your team’s operating creates
                      amazing UI/UX experiences.{" "}
                    </p>
                    <a
                      className="btn btn-style btn-primary mt-sm-5 mt-4 mr-2"
                      href="about.html"
                    >
                      {" "}
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div>
        <div className="item">
          <li>
            <div className="slider-info banner-view banner-top3 bg bg2">
              <div className="banner-info">
                <div className="container">
                  <div className="banner-info-bg">
                    <h6>We are Creative</h6>
                    <h5>World IT Service company </h5>
                    <p className="mt-md-4 mt-3">
                      Get the most of reduction in your team’s operating creates
                      amazing UI/UX experiences.{" "}
                    </p>
                    <a
                      className="btn btn-style btn-primary mt-sm-5 mt-4 mr-2"
                      href="about.html"
                    >
                      {" "}
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </div>
      </div>
    </div>
  </section>
  {/* /main-slider */}
</>

        </Fragment>
    )
}
export default MainSlider;