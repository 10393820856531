import React, { Fragment } from "react";
function Header(){
    return (
        <Fragment>
             
    <section class="w3l-top-header">
      <div class="container">
          <div class="top-header-2">
              <div class="d-grid main-top">
                  <div class="top-headers">
                      <ul class="left-info">
                          <li>
                              <span class="fa fa-phone"></span><a href="tel:+919229452907">9229452907</a>
                          </li>
                          {/* <li class="">
                              <a href="#help" class="d-md-block d-none">Help Center</a>
                          </li> */}
                          <li class="">
                              <a href="mailto:raj@rajdhanitec.com" class="d-md-block d-none">raj@rajdhanitec.com</a>
                          </li>
                      </ul>
                  </div>
                  <div class="top-headers">
                      <ul>
                          <li>
                              <a href="#facebook"><span class="fa fa-facebook"></span></a>
                          </li>
                          <li>
                              <a href="#twitter"><span class="fa fa-twitter"></span></a>
                          </li>
                          <li>
                              <a href="#instagram"><span class="fa fa-instagram"></span></a>
                          </li>
                          <li class="mr-0">
                              <a href="#linkedin"><span class="fa fa-linkedin"></span></a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
      </div>
  </section>
  <header id="site-header" className="fixed-top">
  <section className="w3l-header-4">
    <div className="container">
      <nav className="navbar navbar-expand-lg navbar-light">
        <h1>
          <a className="navbar-brand" href="./">
            <span className="fa fa-clone" /> Rajdhani Technologies
          </a>
        </h1>
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="fa icon-expand fa-bars" />
          <span className="fa icon-close fa-times" />
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <a className="nav-link" href="./">
                Home{" "}
              </a>
            </li>
            <li className="nav-item @@about__active">
              <a className="nav-link" href="./about">
                About
              </a>
            </li>
            <li className="nav-item @@services__active">
              <a className="nav-link" href="services.html">
                Our Services
              </a>
            </li>
            <li className="nav-item @@contact__active">
              <a className="nav-link" href="contact.html">
                Contact
              </a>
            </li>
          </ul>
          <ul className="navbar-nav search-right mt-lg-0 mt-2">
            {/* <li className="nav-item mr-2" title="Search">
              <a href="#search" className="btn search-search">
                <span className="fa fa-search" aria-hidden="true" />
              </a>
            </li> */}
            <li className="nav-item">
            
              <a
                href="tel:+919229452907"
                className="btn btn-primary d-none d-lg-block btn-style mr-3"
              >
               <span class="fa fa-phone"></span> 9229452907
              </a>
              
            </li>
            
          </ul>
          {/* //toggle switch for light and dark theme */}
          {/* search popup */}
          {/* <div id="search" className="pop-overlay">
            <div className="popup">
              <form action="error.html" method="GET" className="d-flex">
                <input
                  type="search"
                  placeholder="Search.."
                  name="search"
                  required="required"
                  autofocus=""
                />
                <button type="submit">
                  <span className="fa fa-search" />
                </button>
                <a className="close" href="#close">
                  ×
                </a>
              </form>
            </div>
          </div> */}
          {/* /search popup */}
        </div>
        {/* toggle switch for light and dark theme */}
        {/* <div className="mobile-position">
          <nav className="navigation">
            <div className="theme-switch-wrapper">
              <label className="theme-switch" htmlFor="checkbox">
                <input type="checkbox" id="checkbox" />
                <div className="mode-container">
                  <i className="gg-sun" />
                  <i className="gg-moon" />
                </div>
              </label>
            </div>
          </nav>
        </div> */}
      </nav>
    </div>
  </section>
</header>

        </Fragment>
    )
}
export default Header