import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../pages/Footer";
import Header from "../pages/Header";


function WebLayout(){
    return(

        <Fragment>
            <link rel="stylesheet" href="./assets/css/style-starter.css"></link>
            <Header/>
            <Outlet/>
            <Footer/>
        </Fragment>
    )
}
export default WebLayout;