import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "./Footer";
import Header from "./Header";

function About(){
  useEffect(()=>{
    document.title='Rajdhani Technologies | About Us';
   })
    return(
        <Fragment>
            <>
            <Helmet>
            <title>Nested Title</title>
            <meta name="description" content="Nested component" />
           </Helmet>
  <section className="w3l-breadcrumb">
    <div className="container">
      <ul className="breadcrumbs-custom-path">
        <li>
          <a href="index.html">Home</a>
        </li>
        <li className="active">/ About Us</li>
      </ul>
    </div>
  </section>
  {/* about section */}
  <section className="w3l-text-6 py-5" id="about">
    <div className="text-6-mian py-lg-5 py-md-4">
      <div className="container">
        <div className="row top-cont-grid align-items-center">
          <div className="col-lg-6 left-img pr-lg-4">
            <img
              src="assets/images/about1.jpg"
              alt=""
              className="img-responsive radius-image img-fluid"
            />
          </div>
          <div className="col-lg-6 text-6-info mt-lg-0 mt-md-5 mt-4">
            <h3 className="title-big mw-100">
              The Largest Business Expert ! Successfully Providing Business
              Solution from 20 years
            </h3>
            <p className="mt-md-4 mt-3">
              Aenean pulvinar pharetra pellentesque. Cras dignissim, sapien ac
              tempus bibendum, eros massa fermentum massa, in eleifend ipsum
              quam vel magna.Maecenas viverra, leo eget semper ultrices.
            </p>
            <p className="mt-3">
              Pellen tesque libero ut justo, ultrices in ligula. Semper at.
              Lorem init ipsum dolor sit amet elit. Dolor ipsum non velit,
              culpa! ut justo.
            </p>
            <a href="#more" className="btn btn-style btn-primary mt-sm-5 mt-4">
              Read More
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* //about section */}
  {/* card section */}
  <section className="w3l-features-4">
    <div className="features4-block pb-5">
      <div className="container pb-lg-5 pb-md-4 pb-3">
        <div className="row features4-grids">
          <div className="col-xl-3 col-sm-6">
            <div className="features4-grid active">
              <div className="feature-images">
                <span className="fa fa-desktop" aria-hidden="true" />
              </div>
              <h5>
                <a href="#url">Monitoring &amp; Review</a>
              </h5>
              <a href="#readmore" className="grid-link">
                Read More <span className="fa fa-arrow-right" />
              </a>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mt-sm-0 mt-4">
            <div className="features4-grid">
              <div className="feature-images">
                <span className="fa fa-sign-language" aria-hidden="true" />
              </div>
              <h5>
                <a href="#url">Authorised Business Brand</a>
              </h5>
              <a href="#readmore" className="grid-link">
                Read More <span className="fa fa-arrow-right" />
              </a>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mt-xl-0 mt-4">
            <div className="features4-grid">
              <div className="feature-images">
                <span className="fa fa-ravelry" aria-hidden="true" />
              </div>
              <h5>
                <a href="#url">Best Business Solutions</a>
              </h5>
              <a href="#readmore" className="grid-link">
                Read More <span className="fa fa-arrow-right" />
              </a>
            </div>
          </div>
          <div className="col-xl-3 col-sm-6 mt-xl-0 mt-4">
            <div className="features4-grid">
              <div className="feature-images">
                <span className="fa fa-bullhorn" aria-hidden="true" />
              </div>
              <h5>
                <a href="#url">Digital Product Development</a>
              </h5>
              <a href="#readmore" className="grid-link">
                Read More <span className="fa fa-arrow-right" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* //card section */}
  <section className="w3l-content-4 py-5" id="features">
    <div className="content-4-main py-lg-5 py-md-4 py-2">
      <div className="container">
        <div className="content-info-in row align-items-center">
          <div className="content-left col-lg-6">
            <div className="row content4-right-grids mb-sm-5 mb-4 pb-3">
              <div className="col-2 content4-right-icon">
                <div className="content4-icon icon-clr1">
                  <span className="fa fa-envelope-open-o" />
                </div>
              </div>
              <div className="col-10 content4-right-info pl-lg-5">
                <h6>
                  <a href="#url">Email marketing services.</a>
                </h6>
                <p>
                  Lorem ipsum dolor sit amet, Ea consequuntur illum facere
                  aperiam optio adipisicing.
                </p>
              </div>
            </div>
            <div className="row content4-right-grids mb-sm-5 mb-4 pb-3">
              <div className="col-2 content4-right-icon">
                <div className="content4-icon icon-clr2">
                  <span className="fa fa-stack-exchange" />
                </div>
              </div>
              <div className="col-10 content4-right-info pl-lg-5">
                <h6>
                  <a href="#url"> Profitable AdWords campaigns.</a>
                </h6>
                <p>
                  Lorem ipsum dolor sit amet, Ea consequuntur illum facere
                  aperiam optio adipisicing.
                </p>
              </div>
            </div>
            <div className="row content4-right-grids">
              <div className="col-2 content4-right-icon">
                <div className="content4-icon icon-clr3">
                  <span className="fa fa-dollar" />
                </div>
              </div>
              <div className="col-10 content4-right-info pl-lg-5">
                <h6>
                  <a href="#url">Pay per click advertising.</a>
                </h6>
                <p>
                  Lorem ipsum dolor sit amet, Ea consequuntur illum facere
                  aperiam optio adipisicing.
                </p>
              </div>
            </div>
          </div>
          <div className="content-right col-lg-6 mt-lg-0 mt-5">
            <img
              src="assets/images/about2.jpg"
              alt=""
              className="img-responsive radius-image img-fluid"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  {/* middle */}
  <div className="middle py-5">
    <div className="container py-lg-5 py-md-4">
      <div className="welcome-left text-center py-lg-3">
        <h5 className="title-small">We are the best</h5>
        <h3 className="title-big mb-4 mx-0 mw-100">
          Company has been giving best consultation to top USA’s Business
          companies since 1985.
        </h3>
        <p className="pr-lg-5">
          Start working with us that can provide everything you need to generate
          awareness, drive traffic, connect with us. You can test free trail
          today.
        </p>
        <div className="buttons mt-md-5 mt-4">
          <a
            href="#getstarted"
            className="btn btn-style btn-primary mr-sm-2 mr-1"
          >
            Get Started now
          </a>
          <a
            href="#freetrail"
            className="btn btn-style btn-outline-primary ml-sm-2"
          >
            Free Trail
          </a>
        </div>
      </div>
    </div>
  </div>
  {/* //middle */}
  <div className="team-area py-5" id="people">
    <div className="container py-lg-5 py-md-4 py-2">
      <span className="title-small text-center"> Our People</span>
      <h3 className="title-big text-center"> Explore Solutions</h3>
      <div className="row mt-lg-5 mt-4">
        <div className="col-lg-3 col-sm-6 col-xs-12">
          <div className="single-team">
            <div className="img-area">
              <img
                src="assets/images/team1.jpg"
                className="img-fluid radius-image"
                alt=""
              />
              <div className="social">
                <ul className="list-inline">
                  <li>
                    <a href="#url">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#url">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#url">
                      <i className="fa fa-pinterest" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="img-text">
              <h4>Daniel Roberto</h4>
              <p>Founder and CEO</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12 mt-sm-0 mt-5">
          <div className="single-team">
            <div className="img-area">
              <img
                src="assets/images/team2.jpg"
                className="img-fluid radius-image"
                alt=""
              />
              <div className="social">
                <ul className="list-inline">
                  <li>
                    <a href="#url">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#url">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#url">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="img-text">
              <h4>Richard Elison</h4>
              <p>Co Founder and COO</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12 mt-lg-0 mt-5">
          <div className="single-team">
            <div className="img-area">
              <img
                src="assets/images/team3.jpg"
                className="img-fluid radius-image"
                alt=""
              />
              <div className="social">
                <ul className="list-inline">
                  <li>
                    <a href="#url">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#url">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#url">
                      <i className="fa fa-pinterest" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="img-text">
              <h4>Elizabeth</h4>
              <p>HR Manager</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 col-xs-12 mt-lg-0 mt-5">
          <div className="single-team">
            <div className="img-area">
              <img
                src="assets/images/team4.jpg"
                className="img-fluid radius-image"
                alt=""
              />
              <div className="social">
                <ul className="list-inline">
                  <li>
                    <a href="#url">
                      <i className="fa fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#url">
                      <i className="fa fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#url">
                      <i className="fa fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="img-text">
              <h4>Robert Branson</h4>
              <p>Project Leader</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</>

        </Fragment>
    )
}
export default About;