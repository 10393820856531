import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../../axiosInstance';
import { useState } from 'react';
import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { ArrowBack } from '@mui/icons-material';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AddCompany() {
  const routeParams = useParams();
  let { id} = useParams();
  const navigate = useNavigate();
  const [CompanyId, setCompanyId] = useState(0);
  const [CompanyName, setCompanyName] = useState('');
  const [CityName, setCityName] = useState('');
  const [Address, setAddress] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [IsActive, setIsActive] = useState(false);
  const routeChange = () =>{ 
    navigate('/admin/company');
  }
  const handleIsActiveChange = (event) => {
    console.log(event.target);
    console.log(IsActive)
    setIsActive(IsActive?false:true)
    console.log(IsActive)
    //setIsActive(event.target.value)
    //setBusinessTypeId(event.target.value);
  };
  useEffect((event)=>{
    if(id!==undefined || id!=null){
      setCompanyId(id);
      axiosInstance.get('company/GetCompanyMaster/' + id).then((response)=>{
          console.log(response.data);
          //setcompanylist(response.data);
          setCompanyName(response.data.CompanyName);
          setCityName(response.data.CityName);
          setAddress(response.data.Address);
          setMobileNo(response.data.MobileNo)
          setIsActive(response.data.IsActive)
      });
     }
  },[])
  const handleSubmit = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
      //  var CompanyName= data.get('CompanyName');
      //  var CityName= data.get('CityName');
      //  var Address= data.get('Address');
      //  var MobileNo= data.get('MobileNo');
      //  var IsActive= data.get('IsActive');
    //    console.log({
    //     CompanyName: data.get('CompanyName'),
    //     CityName: data.get('CityName'),
    //     Address: data.get('Address'),
    //     MobileNo: data.get('MobileNo'),
    //     IsActive: data.get('IsActive')
    //     });
       if(IsActive==null){
        IsActive=false;
       }
       //console.log(IsActive);
       if(CompanyName===""){
        alert("Enter CompanyName ");
        return;
       }
       if(CityName===""){
        alert("Enter CityName ");
        return;
       }
       if(Address===""){
        alert("Enter Address ");
        return;
       }
       if(MobileNo===""){
        alert("Enter MobileNo ");
        return;
       }
       
       if(id===undefined || id ===null){
        axiosInstance.post('Company/PostCompanyMaster',{
          "CompanyId": 0,
          "CompanyName": CompanyName,
          "CityName": CityName,
          "Address": Address,
          "MobileNo": MobileNo,
          "IsActive": IsActive
          },{
              "Content-Type":"application/json"
          }).then((response)=>{
              console.log(response.data);
              navigate('/admin/company');
          });
       }
       else{
        axiosInstance.post('Company/UpdateCompanyMaster/'+ CompanyId,{
          "CompanyId": parseInt(CompanyId),
          "CompanyName": CompanyName,
          "CityName": CityName,
          "Address": Address,
          "MobileNo": MobileNo,
          "IsActive": IsActive
          },{
              "Content-Type":"application/json"
          }).then((response)=>{
              console.log(response.data);
              navigate('/admin/company');
          });
       }
       
        
  };

  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
           <Grid container spacing={2}>
               <Grid item >
               <Button variant="contained" startIcon={<ArrowBack />} onClick={routeChange}>
                Back
              </Button>
               </Grid>
               <Grid item >
                  <Typography component="h1" variant="h5">
                    Add Company
                  </Typography>
               </Grid>     
          </Grid>
          
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="CompanyName"
              label="CompanyName"
              name="CompanyName"
              autoComplete="CompanyName"
              value={CompanyName}
              onChange={(e)=> setCompanyName(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="CityName"
              label="CityName"
              id="CityName"
              autoComplete="CityName"
              value={CityName}
              onChange={(e)=> setCityName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="Address"
              label="Address"
              id="Address"
              autoComplete="Address"
              value={Address}
              onChange={(e)=> setAddress(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="MobileNo"
              label="MobileNo"
              id="MobileNo"
              autoComplete="MobileNo"
              value={MobileNo}
              onChange={(e)=> setMobileNo(e.target.value)}
            />
            
            <FormControlLabel
              control={<Checkbox value={IsActive} color="primary" checked={IsActive} onChange={handleIsActiveChange}
              name="IsActive"/>}
              label="IsActive"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Save
            </Button>
          </Box>
        </Box>
       
      </Container>
  );
}