import React from "react";
import { Fragment } from "react";
function Footer(){
    return(
        <Fragment>
            <section className="w3l-footer-29-main">
          <div className="footer-29 py-5">
            <div className="container py-lg-4">
              <div className="row footer-top-29">
                <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-1 pr-lg-5">
                  <div className="footer-logo mb-4">
                    <a className="navbar-brand" href="index.html">
                      <span className="fa fa-clone" /> Rajdhani Technologies</a>
                  </div>
                  <p>Welcome to Rajdhani Technologies! We are a leading technology company specializing in CCTV services, 
                    IT software development, website design, and mobile app development. Our comprehensive solutions ensure 
                    the highest level of security, innovative software, captivating websites, and dynamic mobile apps. 
                    With a commitment to customer satisfaction, we leverage our expertise to deliver tailored technology solutions 
                    that empower your business in the digital era. Partner with us and experience the power of advanced technology 
                    at Rajdhani Technologies. </p>
                  <div className="main-social-footer-29 mt-4">
                    <a href="#facebook" className="facebook"><span className="fa fa-facebook" /></a>
                    <a href="#twitter" className="twitter"><span className="fa fa-twitter" /></a>
                    <a href="#instagram" className="instagram"><span className="fa fa-instagram" /></a>
                    <a href="#linkedin" className="linkedin"><span className="fa fa-linkedin" /></a>
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-2 mt-sm-0 mt-5">
                  <ul>
                    <h6 className="footer-title-29">Navigation</h6>
                    <li><a href="/about">About Us</a></li>
                    {/* <li><a href="index.html#homeblog"> Blog posts</a></li> */}
                    <li><a href="/services">Services</a></li>
                    {/* <li><a href="contact.html">Contact us</a></li>
                    <li><a href="#components"> Components</a></li> */}
                  </ul>
                </div>
                <div className="col-lg-2 col-md-6 col-sm-5 col-6 footer-list-29 footer-3 mt-lg-0 mt-5">
                  <h6 className="footer-title-29">Sevices</h6>
                  <ul>
                    <li><a href="#traning">Marketing Plans</a></li>
                    <li><a href="#marketplace">Digital Services</a></li>
                    <li><a href="#experts">Wbsite Design</a></li>
                  </ul>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-7 footer-list-29 footer-4 mt-lg-0 mt-5">
                  <h6 className="footer-title-29">Contact Info </h6>
                  <p>Address : Sejbahar Raipur 492015</p>
                  <p className="mt-2">Phone : <a href="tel:+919229452907">9229452907</a></p>
                  <p className="mt-2">Email : <a href="mailto:raj@rajdhanitec.com">raj@rajdhanirec.com</a></p>
                  {/* <p className="mt-2">Support : <a href="mailto:info@support.com">info@support.com</a></p> */}
                </div>
              </div>
            </div>
          </div>
          {/* copyright */}
          <section className="w3l-copyright text-center">
            <div className="container">
              <p className="copy-footer-29">© 2023 Excellence. All rights reserved. Design by <a href="http://rajdhanitec.com" target="_blank">
                  Rajdhani Technologies</a></p>
            </div>
            {/* move top */}
            <button onclick="topFunction()" id="movetop" title="Go to top">
              ⤴
            </button>
            {/* /move top */}
          </section>
          {/* //copyright */}
        </section>
        
        </Fragment>
    )
}
export default Footer;