import React, { Fragment } from "react";
import Header from './Header';
import Footer from './Footer';
import CarouselSlider from "./CarouselSlider";
import MainSlider from "./MainSlider";
import { Helmet } from "react-helmet";
function Index(){
    return (
        <Fragment>
            <Helmet>
            <title>Rajdhani Technologies | Home</title>
            <meta name="description" content="Rajdhani Technologies" />
            </Helmet>
            <CarouselSlider/>
        </Fragment>
    )
}
export default Index;