import logo from './logo.svg';
import './App.css';
import Index from './pages/Index';
import {BrowserRouter as Router,Routes,Route} from 'react-router-dom';
import About from './pages/About';
import WebLayout from './layouts/WebLayout';
import AdminLayout from './layouts/AdminLayout';
import AdminDashboard from './AdminPages/index'
import Setting from './AdminPages/Setting';
import ErrorPage from './pages/ErrorPage';
import Privacy from './pages/Privacy';
import Login from './AdminPages/login';
import SignIn from './AdminPages/SignIn';
import CompanyList from './AdminPages/company/companylist';
import AddCompany from './AdminPages/company/addcompany';
import UserList from './AdminPages/User/userlist';
import AddUser from './AdminPages/User/adduser';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={ <WebLayout/>}>
            <Route index element={<Index/>}/>
            <Route path='about' element={<About/>}/>
            <Route path='privacy' element={<Privacy/>}/>
          </Route>
          <Route path="/admin" element={ <AdminLayout/>}>
            <Route index element={<AdminDashboard/>}/>
            <Route path='company' element={<CompanyList/>}/>
            <Route path='addcompany' element={<AddCompany/>}/>
            <Route path='addcompany/:id' element={<AddCompany/>}/>
            <Route path='userlist' element={<UserList/>}/>
            <Route path='adduser' element={<AddUser/>}/>
            <Route path='adduser/:id' element={<AddUser/>}/>
            <Route path='setting' element={<Setting/>}/>
          </Route>
          <Route path='/admin/login' element={<SignIn/>}/>
          <Route path="*" element={ <ErrorPage/>}/>
        </Routes>        
      </Router>     
    </div>
  );
}

export default App;
