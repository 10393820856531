import { Fragment, useEffect } from "react";

function Privacy(){
   useEffect(()=>{
    document.title='Rajdhani Technologies | Privacy';
   })
    return(
        <Fragment>
        <>
        <section className="w3l-breadcrumb">
    <div className="container">
      <ul className="breadcrumbs-custom-path">
        <li>
          <a href="/">Home</a>
        </li>
        <li className="privacy">/ Privacy</li>
      </ul>
    </div>
  </section>
 
  <section className="w3l-breadcrumb">
    <div className="container">
      <h1>Privacy Policy</h1>
      <br></br>
    <p>At Rajdhani Technologies, we are committed to protecting your privacy. This Privacy Policy describes how we collect, use, and disclose information when you use our mobile application, WhatConnect, and our related services. By using WhatConnect, you agree to the terms outlined in this Privacy Policy. If you do not agree with any of the provisions in this policy, please refrain from using WhatConnect.</p>
    <br></br>
    <div>
      <h2>1. Information We Collect</h2>
      <br></br>
      <h3>1.1 Information You Provide</h3>
      <br></br>
      <p>When you use WhatConnect, we may collect personal information you provide to us, such as your name, email address, phone number, and other contact information. We may also collect information you provide through your interactions with WhatConnect, such as messages, photos, and videos.</p>
      <br></br>
      <h3>1.2 Usage Information</h3>
      <br></br>
      <p>We collect information about how you use WhatConnect, including the features you use, the content you view or interact with, and the time and duration of your activities. This information helps us improve our services and provide a personalized user experience.</p>
      <br></br>
      <h3>1.3 Device Information</h3>
      <br></br>
      <p>We may collect information about your device, including its unique identifiers, operating system, and mobile network information. This information is necessary to ensure the proper functioning of WhatConnect and to provide you with technical support.</p>
      <br></br>
      <h3>1.4 Log Information</h3>
      <br></br>
      <p>When you use WhatConnect, our servers automatically record certain log information, such as your IP address, browser type, referring/exit pages, and operating system. We use this information for system administration, troubleshooting, and to analyze trends.</p>
    </div>

    <div>
      <h2>2. How We Use Your Information</h2>
      <br></br>
      <h3>2.1 Personalization</h3>
      <br></br>
      <p>We use the information we collect to personalize your experience with WhatConnect. This includes displaying customized content, suggesting connections, and optimizing the features and functionality of WhatConnect based on your preferences.</p>
      <br></br>
      <h3>2.2 Communication</h3>
      <br></br>
      <p>We may use your contact information to communicate with you about WhatConnect, including updates, announcements, and important notices. We may also send you promotional offers or other marketing materials, but you can opt out of receiving such communications at any time.</p>
      <br></br>
      <h3>2.3 Analytics and Improvement</h3>
      <br></br>
      <p>We analyze the information we collect to understand user behavior, improve WhatConnect, and develop new features and services. This analysis is performed in an aggregated and anonymized form whenever possible.</p>
      <br></br>
      <h3>2.4 Legal Compliance</h3>
      <br></br>
      <p>We may use and disclose your information to comply with applicable laws, regulations, legal processes, or enforceable governmental requests. We may also use and disclose your information to protect our rights, privacy, safety, or property, as well as that of our users and the public.</p>
    </div>

    <div>
      <h2>3. Information Sharing and Disclosure</h2>
      <br></br>
      <h3>3.1 Service Providers</h3>
      <br></br>
      <p>We may engage trusted third-party service providers to assist us in providing and improving WhatConnect. These service providers may have access to your information solely for the purpose of performing tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
      <br></br>
      <h3>3.2 Business Transfers</h3>
      <br></br>
      <p>In the event of a merger, acquisition, or any form of sale or transfer of some or all of our assets, your information may be included in the transferred assets. We will notify you before your personal information is transferred and becomes subject to a different Privacy Policy.</p>
      <br></br>
      <h3>3.3 Legal Requirements</h3>
      <br></br>
      <p>We may disclose your information if required to do so by law or in response to valid legal requests, such as court orders or subpoenas.</p>
    </div>

    <div>
      <h2>4. Data Security</h2>
      <br></br>
      <p>We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the Internet or electronic storage is 100% secure. Therefore, we cannot guarantee absolute security.</p>
    </div>

    <div>
      <h2>5. Children's Privacy</h2>
      <br></br>
      <p>WhatConnect is not intended for use by individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to delete it.</p>
    </div>

    <div>
      <h2>6. Changes to This Privacy Policy</h2>
      <br></br>
      <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website or through other communication channels. It is your responsibility to review the Privacy Policy periodically for any changes.</p>
    </div>

    <div>
      <h2>7. Contact Us</h2>
      <br></br>
      <p>If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:</p>

      <p>Rajdhani Technologies
        Raipur Sejbahar 492015
        9229452907</p>
    </div>
    </div>
  </section>


        </>
        </Fragment>
    )
}
export default Privacy;