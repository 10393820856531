import { Fragment, useEffect, useState } from "react";
import axiosInstance from '../../axiosInstance';
import { Button, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
function UserList(){
    let navigate =useNavigate();
    const OnEditClick=(id)=>{
      navigate('/admin/adduser/' + id);
    }
    const routeChange = () =>{ 
        navigate('/admin/adduser');
      }
    const [ userlist, setuserlist ] = useState([]);
    useEffect(()=>{
        axiosInstance.get('Auth/GetUserMasters').then((response)=>{
        console.log(response.data);
        setuserlist(response.data);
       });
       
    },[])
    return (
        <Fragment>
          
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
               <Grid item xs={2} sm={4} md={4}>
               <Button variant="contained"  onClick={routeChange}>
                Add user
              </Button>
               </Grid>
               <Grid item xs={2} sm={4} md={4}>
                  <Typography component="h1" variant="h5">
                    User list
                  </Typography>
               </Grid>     
          </Grid>
          <br></br>
           <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">
            ID
          </th>
          <th scope="col">
            Name
          </th>
          <th scope="col">
            Username
          </th>
          <th scope="col">
            Mobile
          </th>
          <th scope="col">
            
          </th>
        </tr>
      </thead>
      <tbody>
        {userlist.map((item) => (
          <tr key={item.UserId}>
            <td>{item.UserId}</td>
            <td>{item.Name}</td>
            <td>{item.UserName}</td>
            <td>{item.MobileNo}</td>
            <td><EditIcon onClick={()=>OnEditClick(item.UserId)} /></td>
          </tr>
        ))}
      </tbody>
    </table>
        </Fragment>
    )
}
export default UserList;