import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useNavigate } from "react-router-dom";
import axiosInstance from '../../axiosInstance';
import { useEffect } from 'react';
import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useParams } from "react-router-dom";

import axios from "axios";
import {apiurl} from '../../constant'
import { ArrowBack } from '@mui/icons-material';
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function AddUser() {

  const routeParams = useParams();
  let { id} = useParams();
  const navigate = useNavigate();
  const [UserID,setUserId]=useState(0);
  const [CompanyId, setCompanyId] = React.useState('');
  const [BusinessTypeId, setBusinessTypeId] = useState('');
  const [Name, setName] = useState('');
  const [UserName, setUserName] = useState('');
  const [Password, setPassword] = useState('');
  const [EmailId, setEmailId] = useState('');
  const [CityName, setCityName] = useState('');
  const [Address, setAddress] = useState('');
  const [MobileNo, setMobileNo] = useState('');
  const [Role, setRole] = useState('');
  const [IsActive, setIsActive] = useState(false);
  
  const [companylist, setcompanylist ] = useState([]);
  const [businessTypelist, setbusinessTypelist ] = useState([]);
  const current = new Date();
  const date = `${current.getMonth()+1}-${current.getDate()}-${current.getFullYear()}`;

  const [ExpiryDate, setExpiryDate] = React.useState(dayjs(date));
  const routeChange = () =>{ 
    navigate('/admin/userlist');
  }
  const handleCompanyChange = (event) => {
    console.log(event.target.value);
    setCompanyId(event.target.value);
  };
  const handleBusinessTypeChange = (event) => {
    console.log(event.target.value);
    setBusinessTypeId(event.target.value);
  };
  const handleIsActiveChange = (event) => {
    console.log(event.target);
    console.log(IsActive)
    setIsActive(IsActive?false:true)
    console.log(IsActive)
    //setIsActive(event.target.value)
    //setBusinessTypeId(event.target.value);
  };
    //   const handleExpiryDateChange = (event) => {
    //     console.log(event.target.value);
    //     setExpiryDate(dayjs(event.target.value));
    //   };
    console.log('routeParams');
    console.log(routeParams);
    console.log(id);
    useEffect((event)=>{
        axiosInstance.get('Company/GetCompanyMasters').then((response)=>{
        console.log(response.data);
        setcompanylist(response.data);
       });
       axiosInstance.get('BusinessType/GetBusinessTypeMaster').then((response)=>{
        console.log(response.data);
        setbusinessTypelist(response.data);
       });
       if(id!==undefined || id!=null){
        setUserId(id);
        axiosInstance.get('Auth/GetUserMaster/' + id).then((response)=>{
            console.log(response.data);
            //setcompanylist(response.data);
            setName(response.data.Name);
            setUserName(response.data.UserName);
            setPassword(response.data.Password);
            setEmailId(response.data.EmailId);
            setCityName(response.data.CityName);
            setAddress(response.data.Address)
            setMobileNo(response.data.MobileNo)
            setExpiryDate(dayjs(response.data.ExpiryDate))
            setRole(response.data.Role)
            setIsActive(response.data.IsActive)
            setCompanyId(response.data.CompanyId)
            setBusinessTypeId(response.data.BusinessTypeId)
        });
       }
       
    },[])
  const handleSubmit = (event) => {
    event.preventDefault();
    
    const data = new FormData(event.currentTarget);
      //  var CompanyId= data.get('CompanyId');
      //  var BusinessTypeId= data.get('BusinessTypeId');
      //  var Name= data.get('Name');
      //  var UserName= data.get('UserName');
      //  var Password= data.get('Password');
      //  var EmailId= data.get('EmailId');
      //  var CityName= data.get('CityName');
      //  var Address= data.get('Address');
      //  var MobileNo= data.get('MobileNo');
      //  var Role= data.get('Role');
       //var ExpiryDate= expirydate.format(); //data.get('ExpiryDate');
       //ExpiryDate= Expirydate.format();
       
       console.log(IsActive);
       console.log(id)
       setUserId(id);
       console.log('UserID')
       console.log(UserID)
       if(IsActive==null){
        IsActive=false;
       }
       console.log({
        CompanyId:CompanyId,
        BusinessTypeId:BusinessTypeId,
        Name:Name,
        UserName: UserName,
        Password:Password,
        EmailId:EmailId,
        CityName: CityName,
        Address: Address,
        MobileNo: MobileNo,
        ExpiryDate:ExpiryDate.format(),
        Role:Role,
        IsActive: IsActive
        });
       
       
       //console.log(IsActive);
       console.log(Name)
       if(CompanyId===""){
        alert("Select Company");
        return;
       }
       if(BusinessTypeId===""){
        alert("Select Business type")
        return;
       }
       if(Name===""){
        alert("Enter Name");
        return;
       }
       if(UserName===""){
        alert("Enter UserName ");
        return;
       }
       if(Password===""){
        alert("Enter Password");
        return;
       }
       if(EmailId===""){
        alert("Enter Email");
        return;
       }
       if(CityName===""){
        alert("Enter CityName ");
        return;
       }
       if(Address===""){
        alert("Enter Address ");
        return;
       }
       if(MobileNo===""){
        alert("Enter MobileNo ");
        return;
       }
       if(ExpiryDate==="" || ExpiryDate.format()==="Invalid Date"){
        alert("Enter ExpiryDate");
        return;
       }
       if(Role===""){
        alert("Enter Role");
        return;
       }
       
       if(id===undefined || id ===null)
       {
        console.log("adding data")
        
        axiosInstance.post('Auth/RegisterUser',{
            "UserId": 0,
            "CompanyId": CompanyId,
            "Name": Name,
            "UserName": UserName,
            "Password": Password,
            "EmailId": EmailId,
            "CityName": CityName,
            "Address": Address,
            "MobileNo": MobileNo,
            "BusinessTypeId": BusinessTypeId,
            "ExpiryDate": ExpiryDate.format(),
            "Role": Role,
            "IsActive": IsActive
        },
        {
          "Content-Type":"application/json"
      }).then((response)=>{
            console.log(response.data);
            alert("Record save successfully")
            navigate('/admin/userlist');
        });
       }
       else{
        
        axiosInstance.post('Auth/UpdateUserMaster',{
            "UserId": parseInt(UserID),
            "CompanyId": CompanyId,
            "Name": Name,
            "UserName": UserName,
            "Password": Password,
            "EmailId": EmailId,
            "CityName": CityName,
            "Address": Address,
            "MobileNo": MobileNo,
            "BusinessTypeId": BusinessTypeId,
            "ExpiryDate":ExpiryDate.format(),
            "Role": Role,
            "IsActive": IsActive
        },
        {
          "Content-Type":"application/json"
      }).then((response)=>{
            console.log(response.data);
            alert("Updated successfully")
            navigate('/admin/userlist');
        });
       }
  };

  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
               <Grid item xs={2} sm={4} md={4}>
               <Button variant="contained" startIcon={<ArrowBack />} onClick={routeChange}>
                Back
              </Button>
               </Grid>
               <Grid item xs={2} sm={4} md={4}>
                  <Typography component="h1" variant="h5">
                    Add User
                  </Typography>
               </Grid>     
          </Grid>
          <br></br>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <FormControl fullWidth>
            <InputLabel id="lCompanyID">Company</InputLabel>
            <Select
                labelId="lCompanyID"
                id="CompanyId"
                name='CompanyId'
                value={CompanyId}
                label="Company"
                onChange={handleCompanyChange}
            >
                {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
                <MenuItem value="">
                <em>None</em>
                </MenuItem>
                {companylist.map((company) => (
                    <MenuItem
                    value={company.CompanyId}
                    >
                    {company.CompanyName}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
            <div>
                <br></br>
            </div>          
          <FormControl fullWidth>
            <InputLabel id="lBusinessTypeId">BusinessType</InputLabel>
            <Select
                labelId="lBusinessTypeId"
                id="BusinessTypeId"
                name='BusinessTypeId'
                value={BusinessTypeId}
                label="BusinessType"
                onChange={handleBusinessTypeChange}
            >
                <MenuItem value="">
                <em>None</em>
                </MenuItem>
                {businessTypelist.map((businesstype) => (
                    <MenuItem
                    value={businesstype.BusinessTypeId}
                    >
                    {businesstype.BusinessTypeName}
                    </MenuItem>
                ))}
            </Select>
            </FormControl>
            <TextField
              margin="normal"
              required
              fullWidth
              id="Name"
              label="Name"
              name="Name"
              value={Name}
              onChange={(e)=> setName(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="UserName"
              label="UserName"
              name="UserName"
              value={UserName}
              onChange={(e)=>setUserName(e.target.value)}
              autoFocus
              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="Password"
              label="Password"
              name="Password"
              value={Password}
              onChange={(e)=> setPassword(e.target.value)}
              autoFocus
              
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="EmailId"
              label="EmailId"
              id="EmailId"
              value={EmailId}
              onChange={(e)=> setEmailId(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="CityName"
              label="CityName"
              id="CityName"
              autoComplete="CityName"
              value={CityName}
              onChange={(e)=> setCityName(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="Address"
              label="Address"
              id="Address"
              autoComplete="Address"
              value={Address}
              onChange={(e)=> setAddress(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="MobileNo"
              label="MobileNo"
              id="MobileNo"
              autoComplete="MobileNo"
              value={MobileNo}
              onChange={(e)=> setMobileNo(e.target.value)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                label="Expiry Date"
                value={ExpiryDate}
                name="ExpiryDate"
                onChange={(newValue) => setExpiryDate(newValue)}
                />
            </DemoContainer>
            </LocalizationProvider>
            <TextField
              margin="normal"
              required
              fullWidth
              name="Role"
              label="Role"
              id="Role"
              autoComplete="Role"
              value={Role}
              onChange={(e)=> setRole(e.target.value)}
            />
            <FormControlLabel
              control={<Checkbox value={IsActive} color="primary" checked={IsActive} onChange={handleIsActiveChange}
              name="IsActive"/>}
              label="IsActive"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
       
      </Container>
  );
}