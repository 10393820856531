import { Fragment } from "react";
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useEffect } from "react";
import { useState } from "react";
import axiosInstance from "../axiosInstance";
import { useNavigate } from "react-router-dom";
function Index(){
  let navigate =useNavigate();
    const Gotolink=(url)=>{
      navigate(url);
    }
   const [userCount,setuserCount]=useState(0);
   const [companyCount,setcompanyCount]=useState(0);
   const [contactCount,setcontactCount]=useState(0);
   useEffect(()=>{
    axiosInstance.get('Common/GetUserCount').then((response)=>{
      console.log(response.data);
      setuserCount(response.data.Data);
     });
     axiosInstance.get('Common/GetCompanyCount').then((response)=>{
      console.log(response.data);
      setcompanyCount(response.data.Data);
     });
     axiosInstance.get('Common/GetContactCount').then((response)=>{
      console.log(response.data);
      setcontactCount(response.data.Data);
     });
   })
    return (
        <Fragment>
          <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
               <Grid item xs={2} sm={4} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Company
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {companyCount}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary" onClick={()=> Gotolink('../admin/company')} >
                        Go to list
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Users
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {userCount}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary" onClick={()=>Gotolink('../admin/userlist')} >
                        Go to list
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item xs={2} sm={4} md={4}>
                  <Card sx={{ maxWidth: 345 }}>
                    <CardActionArea>
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          Contacts
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {contactCount}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <Button size="small" color="primary">
                        Go to list
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
            </Grid>
        </Box>
         
         
        </Fragment>
    )
}
export default Index;